.navbar-links {
  margin-left: auto;
  font-size: 16px;
  text-transform: uppercase;
}
.me-4 {
  color: white !important;
}
.me-4 {
  border-radius: 15px;
}
.me-4:hover {
  color: #808080 !important;
}
.sec-img {
  margin-left: 5px;
}
.test {
  padding: 1px;
}
.noise{
  background-image: url("../../../public/images/sciezki.png");
  background-position: 0 0;
  background-size: 200px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.3);
}