.nav-item a:hover {
  color: #808080 !important;
}

.nav-item a {
  color: white !important;
}
.noise2 {
  background-image: url("../../../public/images/sciezki.png");
  background-position: 0 0;
  background-size: 200px;
  box-shadow: none;
}
.prod-styl a {
  text-decoration: none;
}
