.carousel {
  margin-bottom: 0.2rem;
}
.carousel-caption {
  bottom: 2rem;
  z-index: 10;
}

/* Deklarowanie wysokości ze względu na pozycjonowanie img */
.carousel-item {
  height: 32rem;
  background-color: #777;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
  object-fit: cover;
}

@media (max-width: 912px) {
  .carousel {
    padding: 0;
  }
}
.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}
