.textareaa {
  height: 150px !important;
  resize: none;
}
.map-container-3 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-3 iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.s-tyle {
  margin-right: 5px;
}
@media screen and (max-width: 870px) {
  .cont-style h1 {
    font-size: 29px;
  }
  .cont-style p {
    text-align: left;
  }
  .testt {
    padding-top: 1px !important;
  }
}
