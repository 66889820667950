@media screen and (max-width: 991px) {
  .Test {
    font-size: 15px;
    width: 70vw;
  }
}
@media screen and (max-width: 767px) {
  .Test {
    width: 65vw;
  }

  .theMarkers {
    display: none;
  }
}
@media screen and (max-width: 630px) {
  .Test {
    width: 80vw;
  }
}
.map-container {
  font-size: 12px;
  font-weight: 750;
}
.p-content {
  font-size: 19px;
}
