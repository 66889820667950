.col-lg-4 {
  margin-bottom: 5.5rem;
  text-align: center;
  padding-top: 2rem;
}
@media screen and (max-width: 991px) {
  .col-lg-4 {
    margin-bottom: 5px;
  }
}
